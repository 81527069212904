import React, { useEffect, useState } from "react";
import PaymentMethodModal from "./PaymentMethodModal";
import axios from "axios";
import API_URL from "../../../redux2";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import {
  ONE_DAY_CREDIT,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import { useDispatch } from "react-redux";
import { getLoanProduct, requestLoan } from "../../../services/payment";
import { openModal } from "../../../redux2/modal/modalSlice";
import KachaCreditOTP from "../../../components/FlightBookingSteps/KachaCreditOTP";

const IssueTicket = ({ user, booking, currency }) => {
  const [show, setShow] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [error, setError] = useState(null);
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [kachaInfo, setKachaInfo] = useState(null);

  const dispatch = useDispatch();

  const initiatePayment = async () => {
    if (!paymentMethod) return toast.error("Please select payment method");

    if (!booking?.company && paymentMethod === "credit") {
      if (!selectedCorporate) {
        toast.error("Please select corporate");
        return;
      }
    }

    if (paymentMethod === "wallet") {
      payWithWallet();
      return;
    }

    if (paymentMethod === "credit" || paymentMethod === "USDCredit") {
      payWithCredit();
      return;
    }

    if (paymentMethod === "kacha") {
      payWithKacha();
      return;
    }

    const data = {
      pnr: booking?.flight?.PNR,
      paymentMethod,
    };

    setLoading(true);
    try {
      const res = await axios.post(API_URL + "booking/payment/initiate", data);

      setPayment(res?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIssueTicketModal = () => {
    setShow(true);
  };

  let instructions = [];
  if (payment?.paymentInstructionsAll) {
    instructions = payment?.paymentInstructionsAll;
  } else {
    instructions = payment?.paymentInstructions;
  }

  function payWithWallet() {
    if (
      booking.company &&
      paymentMethod === "wallet" &&
      booking?.company.wallet <
        WALLET_MINIMUM_AMOUNT + booking?.flight?.price + booking?.serviceCharge
    ) {
      toast.error(
        `You can't use wallet for payment. You don't have enough balance. Please use other payment method.`
      );
      return;
    }

    setLoading(true);
    getTicket("wallet")
      .then((res) => {
        if (res.TicketFailureInfo) {
          console.log(res);
          setError(res?.TicketFailureInfo?._attributes?.Message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Booking Successful");

          setTimeout(() => {
            setShow(false);
            window.location.reload();
          }, 700);

          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  function payWithCredit() {
    if (
      booking.company &&
      booking.company.creditType === ONE_DAY_CREDIT &&
      booking.company.creditLimit < booking?.flight?.price &&
      paymentMethod === "credit"
    ) {
      toast.error(
        `You can't use credit for payment. You don't have enough credit limit. Please use other payment method.`
      );
      return;
    }

    if (
      booking.company &&
      paymentMethod === "USDCredit" &&
      booking.company.USDCreditLimit < booking?.flight?.USDPrice
    ) {
      toast.error(
        `You can't use credit for payment. You don't have enough credit limit. Please use other payment method.`
      );
      return;
    }

    setLoading(true);
    getTicket(paymentMethod ?? "credit")
      .then((res) => {
        if (res.TicketFailureInfo) {
          console.log(res);
          setError(res?.TicketFailureInfo?._attributes?.Message);
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Booking Successful");

          setTimeout(() => {
            setShow(false);
            window.location.reload();
          }, 700);

          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function payWithKacha() {
    let totalPrincipal = parseFloat(booking?.totalPrice);

    const kachaReq = {
      phone: booking?.company?.officeNumber,
      // principal: totalPrincipal.toString(),
      principal: "100",
      // phone: "251923130324",
      code: "80000030",
      pnr_number: booking.flight?.PNR,
    };

    try {
      setLoading(true);
      const [data, product] = await Promise.all([
        requestLoan(kachaReq),
        getLoanProduct(kachaReq.phone),
      ]);

      if (data?.initiate) {
        const modalData = {
          kacha: data?.initiate,
          id: booking?._id,
          issueTicket: true,
          product,
        };
        let modalType = "Kacha";
        setKachaInfo(data.initiate);
        setShow(false);

        dispatch(openModal({ modalType, data: modalData }));
      }
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }

    return;
  }

  const handleKachaSubmit = (otp) => {
    if (!otp) {
      toast.error("Please enter OTP");
      return;
    }

    setShow(true);
    setLoading(true);
    getTicket("kacha", otp)
      .then((res) => {
        console.log(res);
        if (res.TicketFailureInfo) {
          console.log(res);
          setError(
            res?.TicketFailureInfo?._attributes?.Message ||
              res.Fault?.faultstring
          );
          setLoading(false);
        } else {
          setLoading(false);
          toast.success("Booking Successful");

          setTimeout(() => {
            setShow(false);
            window.location.reload();
          }, 700);

          return;
        }
      })
      .catch((err) => {
        console.log({ errOR: err });
        setLoading(false);
      });
  };

  const getTicket = (paymentType, otp = "") =>
    new Promise((resolve, reject) => {
      let data = {
        id: booking?._id,
        PNR: booking?.flight?.PNR,
        payment: paymentType,
        userId: user?._id,
        companyId: booking?.company?._id,
        corporateName: selectedCorporate || null,
        otp,
        reference: kachaInfo?.reference,
        code: kachaInfo?.code,
      };
      axios
        .post(API_URL + "flight/ticket", data)
        .then((res) => {
          resolve(res.data?.AirTicketingRsp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  const renderInstructions = () => {
    return (
      <div className="mt-2 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col tracking-wider">
          {instructions?.map((item, index) => (
            <div key={index}>
              <h1 className="text-xl text-center font-semibold underline">
                {item?.type}
              </h1>
              <div key={index} className="flex items-start">
                <div className="flex flex-col">
                  {item?.instructions?.map((instruction, i) => (
                    <span
                      className="text-sm font-semibold text-neutral-900"
                      key={i}
                    >
                      {instruction}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className="cursor-pointer dropdown-item pt-3"
        onClick={handleIssueTicketModal}
      >
        Issue Ticket
      </div>
      <div className="px-4  bg-white ">
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-right"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Payment Method
            </Modal.Title>
          </Modal.Header>

          {loading ? (
            <Loading search />
          ) : (
            !payment?.paymentInstructions && (
              <PaymentMethodModal
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                // enterprise={enterprise}
                company={booking.company}
                booking={booking}
                user={user}
                totalPrice={
                  currency === "USD"
                    ? booking?.USDTotalPrice
                    : booking?.flight?.price + booking?.serviceCharge || 0
                }
                selectedCorporate={selectedCorporate}
                setSelectedCorporate={setSelectedCorporate}
                currency={currency}
              />
            )
          )}

          {paymentMethod !== "wallet" &&
            paymentMethod !== "credit" &&
            paymentMethod !== "USDCredit" &&
            payment && (
              <>
                {loading && <Loading />}
                <div className="p-5">
                  <h1 className="text-2xl text-center font bold capitalize">
                    {paymentMethod}
                  </h1>
                  <div className="px-2 flex justify-center flex-wrap my-2">
                    <button
                      className="bg-slate-300 text-white px-2 py-1 rounded-lg cursor-pointer"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          payment?.paymentReference
                        );
                        toast.success(
                          "Payment Reference code copied to clipboard"
                        );
                      }}
                    >
                      <h1 className="text-xl font-bold flex  ">
                        {payment?.paymentReference}
                        <button className="mx-2 hover:text-green-800  hover:rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </button>
                      </h1>
                    </button>
                  </div>
                  {renderInstructions()}
                </div>
              </>
            )}

          <Modal.Footer>
            <div className="flex items-center justify-end w-full gap-4">
              <button
                type="button"
                className="btn bg-main hover:bg-main-800 text-white border-main-100"
                onClick={initiatePayment}
              >
                Pay
              </button>

              <button
                type="button"
                className="btn bg-red-500 hover:bg-red-500/80  text-white border-main-100"
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      {paymentMethod === "kacha" && (
        <KachaCreditOTP handleSubmit={handleKachaSubmit} booking={booking} />
      )}
    </div>
  );
};

export default IssueTicket;
